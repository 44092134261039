<template>
  <AdminTemplate v-loading="!render">
    <div v-if="countDown" class="countLoading">
      <div class="text-center">
        <h1 class="text-primary font-64">เริ่มทำข้อสอบ!</h1>
        <div class="cicle-count mg-x-auto mg-t-1" style="font-size: 104px">
          {{ countTime }}
        </div>
      </div>
    </div>
    <div v-if="render && exam.step[step.attId - 1] !== undefined">
      <div
        v-if="exam.step[step.attId - 1].type == 'video'"
        class="text-center pd-3"
      >
        <video autoplay width="100%" height="400" controls>
          <source
            :src="
              require('@/assets/exam/video/' +
                exam.step[this.step.attId - 1].file[0])
            "
            type="video/mp4"
          />
        </video>
      </div>
      <!-- <img v-if="exam.step[this.step.attId-1].type == 'file'" :src="getPicture(exam.step[this.step.attId-1].file[0])" alt="">  -->
      <div v-if="exam.step[step.attId - 1].type == 'file'">
        <el-row>
          <el-col :span="16" :offset="4">
            <el-carousel
              :autoplay="false"
              :loop="false"
              trigger="click"
              indicator-position="outside"
              height="70vh"
            >
              <el-carousel-item
                v-for="(data, index) in exam.step[step.attId - 1].file"
                :key="index"
              >
                <img
                  :src="require('@/assets/exam/picture/' + data)"
                  alt=""
                  class="mw-100 h-100"
                />
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-col :span="12" :offset="6" class="mg-t-3 mg-b-5">
      <el-button type="primary" class="w-100" @click="nextStep()">{{
        exam.step.length == step.attId ? "ทำข้อสอบ" : "ถัดไป"
      }}</el-button>
    </el-col>
  </AdminTemplate>
</template>
<script>
import AdminTemplate from "@/template/VoteAdminTemplate";
export default {
  components: {
    AdminTemplate
  },
  data() {
    return {
      countTime: 5,
      countDown: false,
      render: true
    };
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  async mounted() {
    await this.getState();
  },
  methods: {
    getPicture(data = "") {
      // console.log(data);
      if (data) {
        return require("@/assets/exam/picture/" + data);
      } else {
        return "";
      }
    },
    countdown() {
      this.countDown = true;
      if (this.countTime == 5) {
        let obj = {
          roomId: this.room.roomId,
          qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
          attId: Number(this.step.attId) + 1,
          total: false,
          state: "test"
        };
        this.setState(obj);
      }
      if (this.countTime <= 0) {
        this.$router.push("/testing");

        return false;
      }
      setTimeout(() => {
        setTimeout(() => {
          this.countTime--;
          this.countdown();
        }, 900);
      }, 500);
    },
    nextStep() {
      let obj;
      if (this.exam.step.length == this.step.attId) {
        this.render = false;
        this.countdown();
      } else {
        this.render = false;
        obj = {
          roomId: this.room.roomId,
          qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
          attId: Number(this.step.attId) + 1,
          total: false,
          state: "description"
        };
        this.setState(obj);
        setTimeout(() => {
          this.render = true;
        }, 500);

        //this.$router.push('/slide')
      }
    }
  },
  computed: {
    exam() {
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      // console.log("exam", data);
      if (data.length > 0) {
        return data[0];
      } else {
        return "";
      }
    }
  }
};
</script>